import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hero from "../../components/hero";
import CallNowBanner from "../../components/callNowBanner";
import AreaMap from "../../components/areaMap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LogoSchema from "../../components/Schema/logo";
import CallIcon from "../../components/call-icon";
import Layout from "../../components/layout";
import { checkPropertiesForNull } from "../../utils/object";
import { SafeHtmlParser } from "../../components/safe-html-parser";

const CommercialLockReplacements = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				reviewData: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
						bwcReviewCount
						checkatradeReviewCount
					}
				}

				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
				pageData: wpPage(slug: { eq: "commercial-lock-replacement" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					commercialLockReplacementPageFieldGroup {
						commercialLockReplacementBannerSection {
							heading
							description
							cta {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							actions {
								heading
							}
						}
						commercialLockReplacementCtaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
						}
						commercialLockReplacementCtaSection2 {
							heading
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								url
								title
							}
						}
						commercialLockReplacementGoogleMapSection {
							heading
							googleMap
						}
						commercialLockReplacementTextSection1 {
							description
							heading
						}
						commercialLockReplacementTextSection2 {
							description
							heading
						}
						commercialLockReplacementTextSection3 {
							description
							heading
						}
						commercialLockReplacementTwoColumnSection1 {
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						commercialLockReplacementTwoColumnSection2 {
							heading
							description
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, commercialLockReplacementPageFieldGroup },
		schemaLogo,
		reviewData,
		site,
		reviews,
	} = data;

	const {
		commercialLockReplacementBannerSection,
		commercialLockReplacementCtaSection1,
		commercialLockReplacementCtaSection2,
		commercialLockReplacementGoogleMapSection,
		commercialLockReplacementTextSection1,
		commercialLockReplacementTextSection2,
		commercialLockReplacementTextSection3,
		commercialLockReplacementTwoColumnSection1,
		commercialLockReplacementTwoColumnSection2,
	} = commercialLockReplacementPageFieldGroup;

	const siteUrl = site.siteMetadata.siteUrl;

	const reviewCount =
		reviewData.reviewCountFields.checkatradeReviewCount +
		reviewData.reviewCountFields.googleReviewCount +
		reviewData.reviewCountFields.bwcReviewCount;

	const schemaLogoUrl = schemaLogo.publicURL;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/what-we-do/commercial-lock-replacement`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/what-we-do/commercial-lock-replacement`,
		sku: `${siteUrl}/what-we-do/commercial-lock-replacement`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogoUrl}`,
		name: "Seymour Locksmiths",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogoUrl}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Seymour Locksmiths",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/our-locksmith-reviews`,
			datePublished: "2022-01-08T17:58:29+00:00",
			reviewBody:
				"What a fantastic service. I snapped the key in a lock that had been faulty for a while. Jeff arrived quickly and replaced the lock with plenty of spare keys and at a reasonable price. Nice friendly chap. I would definitely recommend and use again in the future. Thank you!",
			author: {
				"@type": "Person",
				name: "Francesca Richards",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Seymour Locksmiths",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: "https://www.seymour-locksmiths.co.uk",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Services",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Commercial Lock Replacement",
				item: "https://www.seymour-locksmiths.co.uk/what-we-do/commercial-lock-replacement",
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{seoFieldGroups?.productSchema?.length > 0 && (
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>
			)}

			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do/commercial-lock-replacement`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{commercialLockReplacementBannerSection &&
				!checkPropertiesForNull(commercialLockReplacementBannerSection, [
					"heading",
				]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser
								htmlContent={
									commercialLockReplacementBannerSection?.description
								}
							/>
						}
						image={
							commercialLockReplacementBannerSection?.backgroundImage.localFile
								.childImageSharp.gatsbyImageData
						}
						heading={commercialLockReplacementBannerSection?.heading}
						hFontSize="text-4xl"
						button={commercialLockReplacementBannerSection?.cta}
						actions={commercialLockReplacementBannerSection?.actions}
					/>
				)}

			{commercialLockReplacementTextSection1 &&
				!checkPropertiesForNull(commercialLockReplacementTextSection1, [
					"heading",
				]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="mb-5">
									<Col
										className="d-flex justify-content-center text-center"
										xs={12}
									>
										<h2 className="text-3xl mb-3 fw-700 lato">
											{commercialLockReplacementTextSection1?.heading}
										</h2>
									</Col>
									<Col className="d-flex justify-content-center" xs={12}>
										<span className="sl-underline d-block" />
									</Col>
								</Row>
								{commercialLockReplacementTextSection1?.description && (
									<div className="text-lg">
										<SafeHtmlParser
											htmlContent={
												commercialLockReplacementTextSection1?.description
											}
										/>
									</div>
								)}
							</Col>
						</Row>
						<span className="hero-overlay" />
					</Container>
				)}
			{commercialLockReplacementTwoColumnSection1 &&
				!checkPropertiesForNull(commercialLockReplacementTwoColumnSection1, [
					"description",
				]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="my-24 mt-below-xl-0">
								<Row className="justify-content-center">
									<Col xs={11} sm={10} xl={9} className="two-column-content">
										{commercialLockReplacementTwoColumnSection1?.description && (
											<SafeHtmlParser
												htmlContent={
													commercialLockReplacementTwoColumnSection1?.description
												}
											/>
										)}
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										commercialLockReplacementTwoColumnSection1?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={
										commercialLockReplacementTwoColumnSection1?.image?.altText
									}
									className="w-100 h-100"
								/>
							</Col>
						</Row>
					</Container>
				)}

			{commercialLockReplacementTextSection2 &&
				!checkPropertiesForNull(commercialLockReplacementTextSection2, [
					"heading",
				]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<h2 className="text-3xl mb-3 fw-700 lato">
									{commercialLockReplacementTextSection2?.heading}
								</h2>

								<span className="sl-underline d-block mb-5" />

								<div className="text-lg">
									{commercialLockReplacementTextSection2?.description && (
										<SafeHtmlParser
											htmlContent={
												commercialLockReplacementTextSection2?.description
											}
										/>
									)}
								</div>
							</Col>
						</Row>
					</Container>
				)}

			{commercialLockReplacementCtaSection1 &&
				!checkPropertiesForNull(commercialLockReplacementCtaSection1, [
					"heading",
				]) && (
					<CallNowBanner
						heading={commercialLockReplacementCtaSection1?.heading}
						button={commercialLockReplacementCtaSection1?.cta1}
						button2={commercialLockReplacementCtaSection1?.cta2}
					/>
				)}

			{commercialLockReplacementTextSection3 &&
				!checkPropertiesForNull(commercialLockReplacementTextSection3, [
					"heading",
				]) && (
					<Container fluid={true} className="my-24" as="section">
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<h2 className="text-3xl mb-3 fw-700 lato">
									{commercialLockReplacementTextSection3?.heading}
								</h2>

								<span className="sl-underline d-block mb-5" />

								{commercialLockReplacementTextSection3?.description && (
									<SafeHtmlParser
										htmlContent={
											commercialLockReplacementTextSection3?.description
										}
									/>
								)}
							</Col>
						</Row>
					</Container>
				)}

			{commercialLockReplacementTwoColumnSection2 &&
				!checkPropertiesForNull(commercialLockReplacementTwoColumnSection2, [
					"heading",
				]) && (
					<Container as="section" fluid={true}>
						<Row>
							<Col xs={12} xl={6} className="px-0">
								<GatsbyImage
									image={
										commercialLockReplacementTwoColumnSection2?.image?.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={
										commercialLockReplacementTwoColumnSection2?.image?.altText
									}
									className="w-100 h-100"
								/>
							</Col>
							<Col xs={12} xl={6} className="my-24">
								<Row className="justify-content-center">
									<Col xs={11} sm={10} className="two-column-content">
										<h2 className="text-3xl lato fw-700 mb-3">
											{commercialLockReplacementTwoColumnSection2?.heading}
										</h2>
										<span className="sl-underline d-block mb-5" />
										{commercialLockReplacementTwoColumnSection2?.description && (
											<SafeHtmlParser
												htmlContent={
													commercialLockReplacementTwoColumnSection2?.description
												}
											/>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				)}
			{commercialLockReplacementCtaSection2 &&
				!checkPropertiesForNull(commercialLockReplacementCtaSection2, [
					"heading",
				]) && (
					<CallNowBanner
						heading={commercialLockReplacementCtaSection2?.heading}
						button={commercialLockReplacementCtaSection2?.cta1}
						button2={commercialLockReplacementCtaSection2?.cta2}
					/>
				)}

			{commercialLockReplacementGoogleMapSection &&
				!checkPropertiesForNull(commercialLockReplacementGoogleMapSection, [
					"heading",
				]) && (
					<AreaMap
						heading={commercialLockReplacementGoogleMapSection?.heading}
						map={commercialLockReplacementGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default CommercialLockReplacements;
